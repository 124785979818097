// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-bagn.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.BAGN,
  domain: 'bayernwerk.de',
  name: 'Bayernwerk Natur GmbH',
  contactEmail: 'support-partner@bayernwerk.de',
  // TODO: clarify and insert correct data --- START
  address: {
    street: 'Lilienthalstraße',
    houseNumber: '7',
    zipCode: '93049',
    city: 'Regensburg',
  },
  districtCourt: 'Regensburg',
  commercialRegisterNumber: 'HRB 9476',
  taxNumber: 'DE 814 365 771',
  board: [],
  chairPerson: 'Gudrun Alt, Dr. Joachim Kabs, Robert Pflügl',
  personResponsible: 'Maximilian Zängl',
  // TODO: clarify and insert correct data --- END
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.bayernwerk.de/de/ueber-uns/impressum.html',
    privacyPolicy: 'https://www.bayernwerk.de/de/ueber-uns/datenschutz.html',
    termsAndConditions: 'https://www.bayernwerk.de/de/ueber-uns/disclaimer.html',
  },
  email: {
    /*
       ---------
       IMPORTANT
       ---------
       These are placeholder values. Replace them as soon as
       this configuration becomes relevant.
    */
    name: 'Bayernwerk Natur GmbH',
    logo: {
      src: 'https://smexnet.sh-netz.com/assets/logo-shng.png',
      alt: 'Schleswig-Holstein Netz GmbH Logo',
      height: '33',
      width: '184',
    },
    imprintAddress: 'Please replace this.',
    imprint: 'Please replace this.',
    contactEmail: 'smexnet@sh-netz.com',
    color: colors.colorPrimary2,
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 184,
      headerLogoHeight: 33,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 184,
      footerLogoHeight: 33,
      favicon: '/favicons/favicon-bage.ico',
    },
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
