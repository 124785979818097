// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-shng.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.SHNG,
  domain: 'sh-netz.com',
  name: 'Schleswig-Holstein Netz GmbH',
  contactEmail: 'smexnet@sh-netz.com',
  address: {
    street: 'Schleswag-HeinGas-Platz',
    houseNumber: '1',
    zipCode: '25451',
    city: 'Quickborn',
  },
  districtCourt: 'Pinneberg',
  commercialRegisterNumber: 'HRB 8122 PI',
  taxNumber: 'DE 267 393 355',
  board: ['Steffen Bandelow', 'Malgorzata Cybulska', 'Dr. Benjamin Merkt'],
  chairPerson: 'Matthias Boxberger',
  personResponsible: 'Ove Struck',
};

const defaultCompanyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.sh-netz.com/de/schleswig-holstein-netz/impressum.html',
    privacyPolicy: 'https://www.sh-netz.com/de/schleswig-holstein-netz/datenschutz.html',
    termsAndConditions: 'https://www.sh-netz.com/de/schleswig-holstein-netz/agb.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 184,
      headerLogoHeight: 33,
      // Todo: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 184,
      footerLogoHeight: 33,
      favicon: '/favicons/favicon-shng.ico',
    },
  },
  email: {
    name: 'Schleswig-Holstein Netz GmbH',
    logo: {
      src: 'https://smexnet.sh-netz.com/assets/logo-shng.png',
      alt: 'Schleswig-Holstein Netz GmbH Logo',
      height: '33',
      width: '184',
    },
    imprintAddress: 'Schleswig-Holstein Netz GmbH | Schleswag-HeinGas-Platz 1 | 25451 Quickborn',
    imprint:
      'Vorstand: Malgorzata Cybulska, Dr. Benjamin Merkt, Stefan Strobl<br />Vorsitzender des Aufsichtsrates: Matthias Boxberger<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: Ove Struck<br /><br />Sitz Quickborn | Amtsgericht Pinneberg | HRB 8122 PI<br />USt-ID-Nr. DE267393355<br />',
    contactEmail: 'smexnet@sh-netz.com',
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: 'Schleswag-HeinGas-Platz 1<br />25451 Quickborn<br />www.sh-netz.com',
    imprint:
      'Vorsitzender des<br />Aufsichtsrates:<br />Matthias Boxberger<br /><br />Vorstand:<br />Malgorzata Cybulska<br />Dr. Benjamin Merkt<br />Stefan Strobl<br /><br />Sitz Quickborn<br />Amtsgericht Pinneberg<br />HRB 8122 PI',
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default defaultCompanyConfig;
