import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Color definitions can be found here:
   * https://confluence.dev.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#003C46',
  colorPrimary1: '#005564',
  colorPrimary2: '#1E898A',
  colorPrimary3: '#38B6AB',
  colorPrimary4: '#96C8CA',
  colorPrimary5: '#F3FAFA',
  colorPrimary5Overlay: hexToRgba('#F3FAFA', 0.8),
  colorPrimary6: '#EBF6F6',

  // Accents colors
  colorAccentsError: '#C22A44',
  colorAccentsWarning: '#FCEC4F',
  colorAccentsSuccess: '#86BC25',
  colorAccentsHighlight: '#353060',

  // Text colors
  colorTextPrimaryHighlight: '#1E898A', // colorPrimary2
  colorTextError: '#C22A44', // colorAccentsError

  // Error colors
  colorError: '#C22A44',
  colorErrorHover: '#8F0011',
  colorErrorActive: '#E8ABB5',
  colorErrorDisabled: '#FBE0E4',
  colorErrorBackground: '#FBE0E4',

  // Warning colors
  colorWarning: '#FCEC4F',
  colorWarningBackground: '#FFF9C1',

  // Success colors
  colorSuccess: '#86BC25',
  colorSuccessBackground: '#E7F3D0',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#353060', // colorAccentsHighlight
  colorTextAvatarHighlight: '#FFFFFF', // colorTextInverted
};

export default colors;
