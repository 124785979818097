// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-ava.svg';
import colors from './AVA.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.AVA,
  domain: 'avacon-netz.de',
  name: 'Avacon Netz GmbH',
  contactEmail: 'smexnet@avacon.de',
  address: {
    street: 'Schillerstraße',
    houseNumber: '3',
    zipCode: '38350',
    city: 'Helmstedt',
  },
  districtCourt: 'Braunschweig',
  commercialRegisterNumber: 'HRB 203312',
  taxNumber: 'DE 281304797',
  board: [],
  chairPerson: 'André Bruschek, Christian Ehret, Frank Schwermer',
  personResponsible: 'Thorsten von Neubeck',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.avacon-netz.de/de/avacon-netz/das-unternehmen-avacon-netz/impressum.html',
    privacyPolicy:
      'https://www.avacon-netz.de/de/avacon-netz/das-unternehmen-avacon-netz/datenschutz.html',
    termsAndConditions:
      'https://www.avacon-netz.de/de/avacon-netz/das-unternehmen-avacon-netz/disclaimer.html',
    sharepointLink:
      'https://eonos.sharepoint.com/:f:/r/sites/SmartExtranetRollOut-Monteursordner/Shared%20Documents/Monteursordner?csf=1&web=1&e=Be98AJ',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 120,
      headerLogoHeight: 24,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 120,
      footerLogoHeight: 24,
      favicon: '/favicons/favicon-ava.ico',
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: 'https://smexnet.avacon.de/assets/logo-ava.png',
      alt: `${baseData.name} logo`,
      height: '33',
      width: '184',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.chairPerson}<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Amtsgericht ${baseData.districtCourt} | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '27px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.chairPerson}<br /><br />Sitz ${baseData.address.city}<br />Amtsgericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingAVA.id,
  },
  moreToolsLinks: {
    materialCatalogExternal:
      'https://eonos.sharepoint.com/:f:/r/sites/SmartExtranetRollOut-Monteursordner/Shared%20Documents/Monteursordner/Materialkatalog?csf=1&web=1&e=Kl6MU6',
    crewCalendar: {
      prod: {
        contractor: 'https://cfx.eon-apps.com/ava-koka',
        default: 'https://pf3.apps.eon.com/ava-koka/shells/abap/FioriLaunchpad.html',
      },
      default: {
        contractor: 'https://qa-cfx.eon-apps.com/ava-koka',
        default: 'https://qf3.apps.eon.com/ava-koka/shells/abap/FioriLaunchpad.html',
      },
    },
    materialInvoicing: {
      prod: 'https://exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=pwg.apps.eon.com&TARGET=https%3A%2F%2Fexsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000036',
      default:
        'https://q-exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=qwg.apps.eon.com&TARGET=https%3A%2F%2Fq-exsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000015',
    },
  },
  purchaseOrganisation: '0901',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
