import type GoogleMapReact from 'google-map-react';
import { type CompanyTag } from 'src/application/theming/companyConfigs/types';

export enum AuthProvider {
  CIAM = 'ciam',
  AAD = 'aad',
  LOCAL = 'local',
}

type LocalAuthenticationProviderConfiguration = {
  name: AuthProvider.LOCAL;
} & BaseAuthenticationProviderConfiguration;

export type AuthenticationProviderConfiguration =
  | CiamAuthenticationProviderConfiguration
  | AadAuthenticationProviderConfiguration
  | LocalAuthenticationProviderConfiguration;

type NewRelicConfiguration = {
  licenseKey: string;
  applicationId: string;
  accountId: string;
  agentId: string;
  trustKey: string;
};

export type Configuration = {
  authentication: AuthenticationConfiguration;
  smartExtranetApi: {
    baseUrl: string;
    apiToken?: string;
    mockedEndpoints?: string[];
    mockedEndpointsAsError?: string[];
    mockedEndpointsAsEmptyResults?: string[];
  };
  smartExtranetPublicApi: {
    baseUrl: string;
  };
  googleMapsJavascriptApi: {
    authentication?: GoogleMapReact.BootstrapURLKeys & { v: string };
  };
  recaptchaSiteKey: string;
  companyTag: CompanyTag;
  maintenanceEnabled?: boolean;
  maintenanceExcludedCompanies?: CompanyTag[];
  serviceDeskUrl: string;
  sentryLogging?: { enabled: true; dsn: string } | { enabled: false };
  newRelicConfiguration?: NewRelicConfiguration;
  // TODO: check if needed, seems to be unused
  azureApplicationInsights?: {
    instrumentationKey: string;
  };
};

export type AuthenticationConfiguration =
  | { isEnabled: false }
  | {
      isEnabled: true;
      defaultProvider: AuthProvider;
      providers: AuthenticationProviderConfiguration[];
    };

type BaseAuthenticationProviderConfiguration = {
  authBaseUrl: string;
  tokenBaseUrl: string;
  logoutBaseUrl: string;
  clientId: string;
  redirectUrl: string;
  scope: string;
  refreshTokenLifetimeMilliseconds?: number;
};

export type CiamAuthenticationProviderConfiguration = {
  name: AuthProvider.CIAM;
  mfaRegisterUrl: string;
} & BaseAuthenticationProviderConfiguration;

type AadAuthenticationProviderConfiguration = {
  name: AuthProvider.AAD;
} & BaseAuthenticationProviderConfiguration;
