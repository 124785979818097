// Default Elevations
//
// Inspired by https://material.io/design/environment/elevation.html#default-elevations

export const elevationNotification = 2000;

export const elevationPopup = 8;
export const elevationTopNavigation = 7;
export const elevationBottomNavigation = 7;
export const elevationDisabledOverlay = 6;
export const elevationSidebar = 3;
export const elevationStickyHeader = 5;
export const elevationLoaderOverlay = 1;
export const elevationDragAndDrop = 1;
export const elevationDropdown = 3;
// Lifts the header above the sidebar to show the bottom shadow
export const elevationSidebarHeader = 1;

const colorBlackAlpha05 = 'rgba(0, 0, 0, 0.05)';
const colorBlackAlpha08 = 'rgba(0, 0, 0, 0.08)';
const colorBlackAlpha16 = 'rgba(0, 0, 0, 0.16)';
const colorBlackAlpha20 = 'rgba(0, 0, 0, 0.20)';

export const shadowPopup = `0 2px 9px 0 ${colorBlackAlpha20}, 0 0 3px 0 ${colorBlackAlpha05}`;
export const shadowDropdown = `0 2px 4px 0 ${colorBlackAlpha16}`;
export const shadowHeader = `0 0 10px 0 ${colorBlackAlpha05}`;
export const shadowStickyTableHeader = `0 2px 1px ${colorBlackAlpha05}`;
export const shadowSidebarHeader = `0 1px 4px ${colorBlackAlpha08}`;
