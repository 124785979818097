export enum SmexNetSupportedLocale {
  German = 'de-DE',
  English_UK = 'en-GB',
  Czech = 'cs-CZ',
  Hungarian = 'hu-HU',
  Swedish = 'sv-SE',
  Polish = 'pl-PL',
}

export const isSmexNetSupportedLocale = (loc: string): loc is SmexNetSupportedLocale =>
  Object.values(SmexNetSupportedLocale).includes(loc as SmexNetSupportedLocale);

export const SmexNetDefaultLocale = SmexNetSupportedLocale.German;

export type I18NSettings = {
  userLocale: SmexNetSupportedLocale;
  defaultLocale: typeof SmexNetDefaultLocale;
};

export type I18NSettingsWithMessages = I18NSettings & {
  messages?: Record<string, string>;
  isPending: boolean;
};
