import { detect } from 'detect-browser';

export const deviceTablet = '(max-width: 1200px)';

/*
 * Targeting only IE 10+ versions
 * https://philipnewcomer.net/2014/04/target-internet-explorer-10-11-css/
 */
export const deviceIe10plus = 'all and (-ms-high-contrast: none), (-ms-high-contrast: active)';

/*
 * Targeting only Edge versions
 * https://browserstrangeness.github.io/css_hacks.html#spartan (CAUTION! Website really ugly!)
 * or https://pixelbar.be/blog/microsoft-edge-internet-explorer-10-und-ie11-via-media-queries-css-ansprechen/
 */
export const deviceEdge = '@supports (-ms-ime-align: auto)';

export function isWindows(): boolean {
  const browser = detect();
  return (browser?.os ?? '').startsWith('Win');
}

export function isIE(): boolean {
  const browser = detect();
  return browser?.name === 'ie';
}

export function isChrome(): boolean {
  const browser = detect();
  return browser?.name === 'chrome';
}
