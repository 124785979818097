import { mailMessages } from '../../common/commonMessages';
import logo from '../assets/logo-evng.svg';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import colors from './WN.companyConfigColors';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.EVNG,
  domain: 'evng.de',
  name: 'ELE Verteilnetz GmbH',
  contactEmail: 'smexnet@evng.de',
  address: {
    street: 'Ebertstraße',
    houseNumber: '30',
    zipCode: '45879',
    city: 'Gelsenkirchen',
  },
  districtCourt: 'Gelsenkirchen',
  commercialRegisterNumber: 'HR B 8405',
  taxNumber: 'DE 8146 16 379',
  board: ['Wolfgang Boris Pateisky', 'Michael Ortmann'],
  chairPerson: '',
  personResponsible: 'Christoph Heinrichs',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `https://www.${baseData.domain}/impressum.html`,
    privacyPolicy: `https://www.${baseData.domain}/datenschutz.html`,
    termsAndConditions: `https://www.${baseData.domain}/vertraege-und-agbs.html`,
  },
  theme: {
    colors,
    icons: {
      headerLogo: logo,
      headerLogoWidth: 217,
      headerLogoHeight: 30,
      footerLogo: '',
      footerLogoWidth: 0,
      footerLogoHeight: 0,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '30',
      width: '217',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.board.join(
      ', ',
    )}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${
      baseData.name
    }<br />Sitz ${baseData.address.city} | Registergericht ${baseData.districtCourt} | ${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '46px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.board.join(', ')}<br /><br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
