import {
  extraErrorDataIntegration,
  BrowserClient,
  makeFetchTransport,
  defaultStackParser,
  breadcrumbsIntegration,
  globalHandlersIntegration,
  linkedErrorsIntegration,
  dedupeIntegration,
  getCurrentScope,
  httpContextIntegration,
  browserApiErrorsIntegration,
  functionToStringIntegration,
  inboundFiltersIntegration,
} from '@sentry/react';
import packageJson from 'src/../package.json';
import { getConfig } from 'src/config/config';
import { getCurrentEnvironment } from 'src/config/environment';

export const initSentryLogging = (): void => {
  const config = getConfig();
  if (!config.sentryLogging || !config.sentryLogging.enabled) {
    return;
  }
  const client = new BrowserClient({
    dsn: config.sentryLogging.dsn,
    environment: getCurrentEnvironment(),
    release: packageJson.version,
    normalizeDepth: 5,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [
      breadcrumbsIntegration(),
      globalHandlersIntegration(),
      linkedErrorsIntegration(),
      dedupeIntegration(),
      extraErrorDataIntegration(),
      httpContextIntegration(),
      browserApiErrorsIntegration(),
      functionToStringIntegration(),
      inboundFiltersIntegration(),
    ],
  });
  getCurrentScope().setClient(client);
  client.init();
};
