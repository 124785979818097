import ava from 'src/application/theming/companyConfigs/AVA.companyConfig';
import bage from 'src/application/theming/companyConfigs/BAGE.companyConfig';
import bagn from 'src/application/theming/companyConfigs/BAGN.companyConfig';
import edis from 'src/application/theming/companyConfigs/EDIS.companyConfig';
import eeng from 'src/application/theming/companyConfigs/EENG.companyConfig';
import eng from 'src/application/theming/companyConfigs/ENG.companyConfig';
import evng from 'src/application/theming/companyConfigs/EVNG.companyConfig';
import hang from 'src/application/theming/companyConfigs/HANG.companyConfig';
import haw from 'src/application/theming/companyConfigs/HAW.companyConfig';
import lew from 'src/application/theming/companyConfigs/LEW.companyConfig';
import lvn from 'src/application/theming/companyConfigs/LVN.companyConfig';
import regn from 'src/application/theming/companyConfigs/REGN.companyConfig';
import rww from 'src/application/theming/companyConfigs/RWW.companyConfig';
import shng from 'src/application/theming/companyConfigs/SHNG.companyConfig';
import tnng from 'src/application/theming/companyConfigs/TNNG.companyConfig';
import { type CompanyConfig, CompanyTag } from 'src/application/theming/companyConfigs/types';
import vse from 'src/application/theming/companyConfigs/VSE.companyConfig';
import wcon from 'src/application/theming/companyConfigs/WCON.companyConfig';
import wens from 'src/application/theming/companyConfigs/WENS.companyConfig';
import wevg from 'src/application/theming/companyConfigs/WEVG.companyConfig';
import wn from 'src/application/theming/companyConfigs/WN.companyConfig';

export const companiesMap: Record<CompanyTag, CompanyConfig> = {
  [CompanyTag.AVA]: ava,
  [CompanyTag.BAGE]: bage,
  [CompanyTag.BAGN]: bagn,
  [CompanyTag.EDIS]: edis,
  [CompanyTag.EENG]: eeng,
  [CompanyTag.EVNG]: evng,
  [CompanyTag.ENG]: eng,
  [CompanyTag.REGN]: regn,
  [CompanyTag.HANG]: hang,
  [CompanyTag.HAW]: haw,
  [CompanyTag.LEW]: lew,
  [CompanyTag.LVN]: lvn,
  [CompanyTag.RWW]: rww,
  [CompanyTag.SHNG]: shng,
  [CompanyTag.TNNG]: tnng,
  [CompanyTag.VSE]: vse,
  [CompanyTag.WCON]: wcon,
  [CompanyTag.WEVG]: wevg,
  [CompanyTag.WN]: wn,
  [CompanyTag.WENS]: wens,
};
