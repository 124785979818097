import { useQuery } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { ajax } from 'rxjs/ajax';
import { ArrayHelper } from '../arrayHelper';
import {
  type I18NSettings,
  SmexNetDefaultLocale,
  SmexNetSupportedLocale,
  isSmexNetSupportedLocale,
} from './type';
import { assertUnreachable } from 'src/utils/assertUnreachable';

const LocaleLocalStorageKey = 'LOCALE_LOCAL_STORAGE_KEY';

const extractLanguage = (loc: string): string => loc.split('-')[0];

const languageFallbacks: Record<string, SmexNetSupportedLocale> = {
  de: SmexNetSupportedLocale.German,
  en: SmexNetSupportedLocale.English_UK,
  cs: SmexNetSupportedLocale.Czech,
  hu: SmexNetSupportedLocale.Hungarian,
  sv: SmexNetSupportedLocale.Swedish,
  pl: SmexNetSupportedLocale.Polish,
};

const findLocaleMatch = (loc: string): SmexNetSupportedLocale | undefined => {
  const lang = extractLanguage(loc);
  return languageFallbacks[lang];
};

const mapToSmexNetLocale = (browserLanguages: readonly string[]): SmexNetSupportedLocale =>
  ArrayHelper.findMap(browserLanguages, (l) => findLocaleMatch(l)) ?? SmexNetDefaultLocale;

const getLocale = (): SmexNetSupportedLocale => {
  try {
    const localeFromStorage = window.localStorage.getItem(LocaleLocalStorageKey);
    if (localeFromStorage && isSmexNetSupportedLocale(localeFromStorage)) {
      return localeFromStorage;
    }
    const browserLanguages = window.navigator.languages;
    const locale = mapToSmexNetLocale(browserLanguages);
    window.localStorage.setItem(LocaleLocalStorageKey, locale);
    return locale;
  } catch {
    return SmexNetDefaultLocale;
  }
};

const userI18NLocaleAtom = atom<SmexNetSupportedLocale>(getLocale());

export const useSetUserLocale = () => {
  const [, setUserI18NLocale] = useAtom(userI18NLocaleAtom);
  return useCallback(
    (locale: SmexNetSupportedLocale) => {
      window.localStorage.setItem(LocaleLocalStorageKey, locale);
      setUserI18NLocale(locale);
    },
    [setUserI18NLocale],
  );
};

export const useI18NSettingsByFeatureFlag = (): I18NSettings => {
  // This feature flag was causing issues in feature flag migration to
  // React Query. Enable, when multi-language feature is done.
  const enableLanguageSelect = false;
  return useI18NSettings(
    enableLanguageSelect ? LocaleSource.FromLocalStorageOrFallback : LocaleSource.UseDefaultLocale,
  );
};

export enum LocaleSource {
  FromLocalStorageOrFallback = 'FromLocalStorageOrFallback',
  UseDefaultLocale = 'UseDefaultLocale',
}

export const useI18NSettings = (localeSource: LocaleSource): I18NSettings => {
  const languageFromStore = useAtomValue(userI18NLocaleAtom);
  const getUserLocaleBySource = () => {
    switch (localeSource) {
      case LocaleSource.UseDefaultLocale:
        return SmexNetDefaultLocale;
      case LocaleSource.FromLocalStorageOrFallback:
        return languageFromStore;
      default:
        return assertUnreachable(localeSource);
    }
  };
  const userLocale: SmexNetSupportedLocale = getUserLocaleBySource();

  return {
    userLocale,
    defaultLocale: SmexNetDefaultLocale,
  };
};

export const useI18NMessages = (userLocale: SmexNetSupportedLocale) =>
  useQuery({
    queryKey: ['messages', userLocale],
    queryFn: () =>
      ajax
        .getJSON<Record<string, string>>(`/translations/translations-${userLocale}.json`)
        .toPromise(),
    gcTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
  });
