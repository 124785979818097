import { type CSSProperties, useRef } from 'react';
import styled from 'styled-components';
import * as devices from './devices';
import * as elevations from './elevations';
import Loader from './Loader';
import { DSO_THEME_COLORS } from 'src/application/theming/companyConfigs/helper/dsoTheme';

interface LoaderOverlayProps {
  backgroundColor?: string;
  containerStyle?: CSSProperties;
  visible?: boolean;
}

const Container = styled.div<{ backgroundColor: string; visible: boolean }>`
  align-items: flex-start;
  background-color: ${({ backgroundColor }) => backgroundColor};
  inset: 0 0 0 0;
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  position: absolute;
  transition: opacity 0.15s linear 0.2s;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: ${elevations.elevationLoaderOverlay};
`;

const Wrapper = styled.div<{ visible: boolean }>`
  margin: 32px 0 0 0;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  position: sticky;
  top: 25%;
  transition: opacity 0.5s linear 0.5s;

  @media ${devices.deviceIe10plus} {
    left: 50%;
    position: absolute;
    top: 25%;
    transform: translateX(-50%);
  }
`;

const LoaderOverlay = ({
  backgroundColor = DSO_THEME_COLORS.colorBackgroundDecorativeGreyLoaderOverlay,
  containerStyle = {},
  visible = false,
}: LoaderOverlayProps) => {
  const containerRef = useRef(null);
  if (!visible) {
    return null;
  }
  return (
    <Container
      backgroundColor={backgroundColor}
      ref={containerRef}
      style={containerStyle}
      visible={visible}
    >
      <Wrapper visible={visible}>
        <Loader size={24} />
      </Wrapper>
    </Container>
  );
};

export default LoaderOverlay;
