// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-bage.svg';
import colors from './BAGE.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.BAGE,
  domain: 'bayernwerk-netz.de',
  name: 'Bayernwerk Netz GmbH',
  contactEmail: 'support-partner@bayernwerk.de',
  address: {
    street: 'Lilienthalstraße',
    houseNumber: '7',
    zipCode: '93049',
    city: 'Regensburg',
  },
  districtCourt: 'Regensburg',
  commercialRegisterNumber: 'HRB 9476',
  taxNumber: 'DE 814 365 771',
  board: [],
  chairPerson: 'Gudrun Alt, Dr. Joachim Kabs, Robert Pflügl',
  personResponsible: 'Maximilian Zängl',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `http://www.${baseData.domain}/de/bayernwerk-netz-gmbh/impressum.html`,
    privacyPolicy: `http://www.${baseData.domain}/de/bayernwerk-netz-gmbh/datenschutz.html`,
    termsAndConditions: `http://www.${baseData.domain}/de/bayernwerk-netz-gmbh/agb.html`,
    sharepointLink:
      'https://www.bayernwerk-netz.de/de/fuer-kommunen-und-partner/partnerfirmen.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 283,
      headerLogoHeight: 75,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 283,
      footerLogoHeight: 75,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '75',
      width: '283',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.chairPerson}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Registergericht ${baseData.districtCourt} | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '46px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.chairPerson}<br /><br />Sitz ${baseData.address.city}<br />Registergericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingBAGE.id,
  },
  moreToolsLinks: {
    crewCalendar: {
      prod: {
        contractor: 'https://cfx.eon-apps.com/bag-koka',
        default: 'https://pf3.apps.eon.com/bag-koka/shells/abap/FioriLaunchpad.html',
      },
      default: {
        contractor: 'https://qa-cfx.eon-apps.com/bag-koka',
        default: 'https://qf3.apps.eon.com/bag-koka/shells/abap/FioriLaunchpad.html',
      },
    },
    materialCatalogExternal:
      'https://www.bayernwerk-netz.de/de/fuer-kommunen-und-partner/partnerfirmen.html',
    materialInvoicing: {
      prod: 'https://exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=pwg.apps.eon.com&TARGET=https%3A%2F%2Fexsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000036',
      default:
        'https://q-exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=qwg.apps.eon.com&TARGET=https%3A%2F%2Fq-exsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000015',
    },
  },
  purchaseOrganisation: '3190',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
