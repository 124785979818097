import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Here we just used the primary WEVG color in different shades
   * Official color definitions can be found here:
   * https://confluence.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#005E86',
  colorPrimary1: '#347DB3',
  colorPrimary2: '#009CDF',
  colorPrimary3: '#40B5E7',
  colorPrimary4: '#80CEEF',
  colorPrimary5: '#CCEBF9',
  colorPrimary5Overlay: hexToRgba('#CCEBF9', 0.8),
  colorPrimary6: '#E5F5FC',

  // Accents colors
  colorAccentsError: '#EB3323',
  colorAccentsWarning: '#F59B00',
  colorAccentsSuccess: '#6BB52B',
  colorAccentsHighlight: '#EB7C15',

  // Text colors
  colorTextPrimaryHighlight: '#009CDF', // colorPrimary2
  colorTextError: '#EB3323', // colorAccentsError

  // Error colors
  colorError: '#EB3323',
  colorErrorHover: '#B76761',
  colorErrorActive: '#761A12',
  colorErrorDisabled: '#F7ADA7',
  colorErrorBackground: '#FCEBE0',

  // Warning colors
  colorWarning: '#F59B00',
  colorWarningBackground: '#FCEBCE',

  // Success colors
  colorSuccess: '#64B42D',
  colorSuccessBackground: '#E1F5D3',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#EB7C15', // colorAccentsHighlight
  colorTextAvatarHighlight: '#FFFFFF', // colorTextSecondary
};

export default colors;
