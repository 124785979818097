import { StageEnvironment } from 'src/config/AppEnvironment';
import { assertUnreachable } from 'src/utils/assertUnreachable';

export const getCiamClientIdForStageEnvironment = (stageEnvironment: StageEnvironment): string => {
  switch (stageEnvironment) {
    case StageEnvironment.Dev:
      return '3MVG9sSN_PMn8tjSodyD28jSJp6PZvUoQEo2kfPVoYOxjleYaCFHo2BfzkutBlui8.ER4gWtNzYD4MJc9vQFL';
    case StageEnvironment.Qa:
      return '3MVG9od6vNol.eBhEpCYDGdnu3D5TrogCJ_dNYNtZg.KaGDUw5N_LOoS0HpOo.co79Lisnj0iP6fpnvZ5KsW6';
    default:
      assertUnreachable(stageEnvironment);
  }
};

export const getAadClientIdForStageEnvironment = (stageEnvironment: StageEnvironment): string => {
  switch (stageEnvironment) {
    case StageEnvironment.Dev:
      return '4187c5cc-24c8-4ea0-bbf9-ebceac48a9d6';
    case StageEnvironment.Qa:
      return '1bfe0066-a632-40af-ba25-1a8215dfe2b3';
    default:
      assertUnreachable(stageEnvironment);
  }
};
