import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import { Font14 } from './fonts/Font';
import { DSO_THEME_COLORS } from 'src/application/theming/companyConfigs/helper/dsoTheme';

interface LoaderProps {
  color?: string;
  size?: number;
  label?: string;
}

interface ColoredCircularProgressProps {
  color: string;
  size: number;
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

const Label = styled(Font14)`
  color: ${DSO_THEME_COLORS.colorTextTertiary};
  margin-left: 8px;
  white-space: nowrap;
`;

const ColoredCircularProgress = styled(({ size }: ColoredCircularProgressProps) => (
  <CircularProgress color="inherit" size={size} />
))`
  color: ${({ color }) => color};
`;

const Loader = ({
  color = DSO_THEME_COLORS.colorIconDecorative,
  size = 16,
  label,
  ...otherProps
}: LoaderProps) => (
  <Wrapper {...otherProps}>
    <ColoredCircularProgress aria-busy="true" color={color} size={size} />
    {!!label && <Label>{label}</Label>}
  </Wrapper>
);

export default Loader;
