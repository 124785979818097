import logo from '../assets/logo-wens.svg';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import colors from './WENS.companyConfigColors';
import { mailMessages } from 'src/application/common/commonMessages';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.WENS,
  domain: 'westenergie-netzservice.de',
  name: 'Westenergie Netzservice GmbH',
  contactEmail: 'smexnet-wens@westenergie.de',
  address: {
    street: 'Florianstraße',
    houseNumber: '15-21',
    zipCode: '44139',
    city: 'Dortmund',
  },
  districtCourt: 'Dortmund',
  commercialRegisterNumber: 'HRB 32731',
  taxNumber: 'DE 3421 84 549',
  board: ['Jürgen Köchling (Sprecher)', 'Cosima Bockelmann'],
  chairPerson: '',
  personResponsible: '',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.westenergie.de/netzservice/de/ueber-uns/impressum.html',
    privacyPolicy: 'https://www.westenergie.de/netzservice/de/ueber-uns/datenschutz.html',
    termsAndConditions: 'https://www.westenergie.de/anlagen',
  },
  theme: {
    colors,
    icons: {
      headerLogo: logo,
      headerLogoWidth: 283,
      headerLogoHeight: 51,
      headerLogoPadding: 'leftOnly',
      footerLogo: '',
      footerLogoWidth: 0,
      footerLogoHeight: 0,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.png`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '51',
      width: '283',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.board.join(
      ', ',
    )}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${
      baseData.name
    }<br />Sitz ${baseData.address.city} | Registergericht ${baseData.districtCourt} | ${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '46px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.board.join(', ')}<br /><br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
