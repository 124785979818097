import { type Path } from 'react-router-dom';

const URL_LENGTH_LIMIT = 2048;

export const getSanitizedLocation = (payloadLocation: Partial<Path>): Partial<Path> => {
  const { origin } = window.location;
  const pathname = payloadLocation.pathname ?? '';
  const search = payloadLocation.search ?? '';
  // The check here is for the tests only.
  // window.location.origin is always set in the browser.
  const urlLength = origin.length + pathname.length + search?.length;
  let sanitizedSearch = search;
  if (urlLength > URL_LENGTH_LIMIT) {
    // If the URL is longer than allowed
    // remove last, possibly truncated filter.
    sanitizedSearch = search
      .slice(0, Math.max(0, URL_LENGTH_LIMIT))
      .split('&')
      .slice(0, -1)
      .join('&');
  }

  return {
    ...payloadLocation,
    search: sanitizedSearch,
  };
};

export function getBaseDomain() {
  const domainParts = window.location.hostname.split('.');
  // this would not work when the country code consists of 2 levels (e.g. co.uk)
  return domainParts.slice(-2).join('.');
}
