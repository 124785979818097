import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Color definitions can be found here:
   * https://confluence.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#00444B',
  colorPrimary1: '#005F69',
  colorPrimary2: '#009BA5',
  colorPrimary3: '#3EBAC2',
  colorPrimary4: '#8CCCD0',
  colorPrimary5: '#ECF5F6',
  colorPrimary5Overlay: hexToRgba('#ECF5F6', 0.8),
  colorPrimary6: '#DCEEF0',

  // Accents colors
  colorAccentsError: '#C81E82',
  colorAccentsWarning: '#E99E38',
  colorAccentsSuccess: '#64B42D',
  colorAccentsHighlight: '#64B42D',

  // Text colors
  colorTextPrimaryHighlight: '#009BA5', // colorPrimary2
  colorTextError: '#C81E82', // colorAccentsError

  // Error colors
  colorError: '#C81E82',
  colorErrorHover: '#95004F',
  colorErrorActive: '#62001C',
  colorErrorDisabled: '#D49FBF',
  colorErrorBackground: '#F5D7E9',

  // Warning colors
  colorWarning: '#F59B00',
  colorWarningBackground: '#FCEBCE',

  // Success colors
  colorSuccess: '#64B42D',
  colorSuccessBackground: '#E7F3D0',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#64B42D', // colorAccentsHighlight
  colorTextAvatarHighlight: '#FFFFFF', // colorTextInverted
};

export default colors;
