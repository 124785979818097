import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Color definitions can be found here:
   * https://confluence.dev.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#04517D',
  colorPrimary1: '#05669D',
  colorPrimary2: '#0080C8',
  colorPrimary3: '#50AFF5',
  colorPrimary4: '#81BBE3',
  colorPrimary5: '#E5F1FB',
  colorPrimary5Overlay: hexToRgba('#E5F1FB', 0.8),
  colorPrimary6: '#CEE9FC',

  // Accents colors
  colorAccentsError: '#F3463F',
  colorAccentsWarning: '#FFE100',
  colorAccentsSuccess: '#39CB2C',
  colorAccentsHighlight: '#04517D', // colorPrimary0

  // Text colors
  colorTextPrimaryHighlight: '#0080C8', // colorPrimary2
  colorTextError: '#F3463F', // colorAccentsError

  // Error colors
  colorError: '#F3463F',
  colorErrorHover: '#BF3632',
  colorErrorActive: '#A4211D',
  colorErrorDisabled: '#F6A09C',
  colorErrorBackground: '#FFF0F0',

  // Warning colors
  colorWarning: '#FFE100',
  colorWarningBackground: '#FFF8C9',

  // Success colors
  colorSuccess: '#39CB2C',
  colorSuccessBackground: '#E4F9E5',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#04517D', // colorAccentsHighlight
  colorTextAvatarHighlight: '#FFFFFF', // colorTextInverted
};

export default colors;
