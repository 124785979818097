// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-tnng.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.TNNG,
  domain: 'travenetz.de',
  name: 'TraveNetz GmbH',
  // TODO: clarify and insert correct data
  contactEmail: 'smexnet@sh-netz.com',
  address: {
    street: 'Geniner Straße',
    houseNumber: '80',
    zipCode: '23560',
    city: 'Lübeck',
  },
  districtCourt: 'Lübeck',
  commercialRegisterNumber: 'HRB 5885',
  taxNumber: 'DE814218133',
  board: [],
  chairPerson: 'Sven Bäumler',
  personResponsible: 'dsb@swhl.de',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `https://www.${baseData.domain}/impressum/`,
    privacyPolicy: `https://www.${baseData.domain}/datenschutz/`,
    termsAndConditions: undefined,
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 193,
      headerLogoHeight: 23,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 193,
      footerLogoHeight: 23,
      favicon: '/favicons/favicon-shng.ico',
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${baseData.domain}/assets/logo-tnng.png`,
      alt: `${baseData.name} Logo`,
      height: '23',
      width: '193',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.chairPerson}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Amtsgericht ${baseData.districtCourt} | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '27px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.chairPerson}<br /><br />Sitz ${baseData.address.city}<br />Amtsgericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  purchaseOrganisation: '1501',
  // TODO: clarify and insert correct data
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
