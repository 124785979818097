import { mailMessages } from '../../common/commonMessages';
import logo from '../assets/logo-eng.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.ENG,
  domain: 'energis-netzgesellschaft.de',
  name: 'energis-Netzgesellschaft mbH',
  contactEmail: 'service@energis-netzgesellschaft.de',
  address: {
    street: 'Heinrich-Böcking-Straße',
    houseNumber: '10 - 14',
    zipCode: '66121',
    city: 'Saarbrücken',
  },
  districtCourt: 'Saarbrücken',
  commercialRegisterNumber: 'HRB 16456',
  taxNumber: 'DE 254 745 180',
  board: [],
  chairPerson: 'Roman Fixemer, Tobias Hugo',
  personResponsible: '',
  // TODO: clarify and insert correct data
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `http://www.${baseData.domain}/impressum.html`,
    privacyPolicy: `http://www.${baseData.domain}/datenschutz.html`,
    termsAndConditions: `http://www.${baseData.domain}/disclaimer.html`,
  },
  theme: {
    colors,
    icons: {
      headerLogo: logo,
      headerLogoWidth: 483,
      headerLogoHeight: 134,
      footerLogo: logo,
      footerLogoWidth: 483,
      footerLogoHeight: 134,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '75',
      width: '283',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.chairPerson}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Registergericht ${baseData.districtCourt} | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '46px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.chairPerson}<br /><br />Sitz ${baseData.address.city}<br />Registergericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
