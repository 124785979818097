import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Color definitions can be found here:
   * https://confluence.dev.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#006081',
  colorPrimary1: '#00749D',
  colorPrimary2: '#0091BB',
  colorPrimary3: '#42B0D2',
  colorPrimary4: '#A1D4EA',
  colorPrimary5: '#E9F3F8',
  colorPrimary5Overlay: hexToRgba('#E9F3F8', 0.8),
  colorPrimary6: '#DEEEF2',

  // Accents colors
  colorAccentsError: '#E63121',
  colorAccentsWarning: '#FFE100',
  colorAccentsSuccess: '#6BB52B',
  colorAccentsHighlight: '#FFE100',

  // Text colors
  colorTextPrimaryHighlight: '#0091BB', // colorPrimary2
  colorTextError: '#E63121', // colorAccentsError

  // Error colors
  colorError: '#E63121',
  colorErrorHover: '#B30000',
  colorErrorActive: '#800000',
  colorErrorDisabled: '#ECB1AC',
  colorErrorBackground: '#FFEAE8',

  // Warning colors
  colorWarning: '#FCEC4F',
  colorWarningBackground: '#FFF9C1',

  // Success colors
  colorSuccess: '#86BC25',
  colorSuccessBackground: '#E4F9E5',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#FFE100', // colorAccentsHighlight
  colorTextAvatarHighlight: '#404040', // colorTextSecondary
};

export default colors;
