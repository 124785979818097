// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-hang.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.HANG,
  domain: 'hansegas.com',
  name: 'HanseGas GmbH',
  // TODO: clarify and insert correct data --- START
  contactEmail: 'smexnet@sh-netz.com',
  address: {
    street: 'Lilienthalstraße',
    houseNumber: '7',
    zipCode: '93049',
    city: 'Regensburg',
  },
  districtCourt: 'Regensburg',
  commercialRegisterNumber: 'HRB 9476',
  taxNumber: 'DE 814 365 771',
  board: [],
  chairPerson: 'Malgorzata Cybulska, Steffen Bandelow',
  personResponsible: 'Maximilian Zängl',
  // TODO: clarify and insert correct data --- END
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.hansegas.com/de/hansegas/impressum.html',
    privacyPolicy: 'https://www.hansegas.com/de/hansegas/datenschutz.html',
    termsAndConditions: 'https://www.hansegas.com/de/hansegas/agb.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 90,
      headerLogoHeight: 33,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 90,
      footerLogoHeight: 33,
      favicon: '/favicons/favicon-shng.ico',
    },
  },
  email: {
    name: 'HanseGas GmbH',
    logo: {
      src: 'https://smexnet.sh-netz.com/assets/logo-hang.png',
      alt: 'HanseGas GmbH Logo',
      height: '33',
      width: '90',
    },
    imprintAddress: 'HanseGas GmbH | Schleswag-HeinGas-Platz 1 | 25451 Quickborn',
    imprint: `Vorsitzende des Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /> Vorstand: ${baseData.board.join(', ')}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${
      baseData.personResponsible
    }<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Registergericht ${
      baseData.districtCourt
    } | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: 'smexnet@sh-netz.com',
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: 'Schleswag-HeinGas-Platz 1<br />25451 Quickborn<br />www.hansegas.com',
    imprint: `Vorsitzende des Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /> Geschäftsführung:<br />${baseData.board.join(', ')}<br /><br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  moreToolsLinks: {
    materialInvoicing: {
      prod: 'https://exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=pwg.apps.eon.com&TARGET=https%3A%2F%2Fexsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000036',
      default:
        'https://q-exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=qwg.apps.eon.com&TARGET=https%3A%2F%2Fq-exsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000015',
    },
  },
  purchaseOrganisation: '1501',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
