// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-lvn.svg';
import colors from './LEW_LVN.companyConfigColors';
import { type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.LVN,
  domain: 'lew-verteilnetz.de',
  name: 'LEW Verteilnetz GmbH',
  contactEmail: '',
  address: {
    street: 'Schaezlerstraße',
    houseNumber: '3',
    zipCode: '86150',
    city: 'Augsburg',
  },
  districtCourt: 'Augsburg',
  commercialRegisterNumber: 'HRB 20929',
  taxNumber: 'DE 2404 32 124',
  board: ['Norbert Wiedemann', 'Josef Wagner'],
  chairPerson: 'Christian Barr',
  personResponsible: '',
};

const company = {
  ...baseData,
  links: {
    imprint: `https://www.${baseData.domain}/impressum/`,
    privacyPolicy: `https://www.${baseData.domain}/datenschutz/`,
    termsAndConditions: `https://www.${baseData.domain}/disclaimer`,
  },
  googleAnalytics: {
    prod: {
      trackingId: 'UA-xxxxxxx-xx',
    },
    qa: {
      trackingId: 'UA-xxxxxxxx-xx',
    },
    dev: {
      trackingId: 'UA-xxxxxxxx-xx',
    },
    development: {
      trackingId: 'UA-xxxxxxxx-xx',
    },
    default: {
      trackingId: 'UA-xxxxxxxx-xx',
    },
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 50,
      headerLogoHeight: 33,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 184,
      footerLogoHeight: 33,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '33',
      width: '50',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführer: ${baseData.board.join(', ')}<br />Vorsitzender des Aufsichtsrates: ${
      baseData.chairPerson
    }<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${
      baseData.name
    }<br />Sitz ${baseData.address.city} | Amtsgericht ${baseData.districtCourt} | ${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Vorsitzender des<br />Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /><br />Geschäftsführer:<br />${baseData.board.join('<br />')}<br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  moreToolsLinks: {
    materialInvoicing: {
      prod: '',
      default: '',
    },
  },
  purchaseOrganisation: '',
  appId: companyTagToAppId[baseData.companyTag],
};
export default company;
