import { type PaddingValue } from 'src/components/layout/header/HeaderLogo';
import { type Address } from 'src/types';

// TODO: move this enum
export enum CompanyTag {
  AVA = 'AVA',
  BAGE = 'BAGE',
  BAGN = 'BAGN',
  EDIS = 'EDIS',
  EENG = 'EENG',
  EVNG = 'EVNG',
  ENG = 'ENG',
  REGN = 'REGN',
  HANG = 'HANG',
  HAW = 'HAW',
  LEW = 'LEW',
  LVN = 'LVN',
  RWW = 'RWW',
  SHNG = 'SHNG',
  TNNG = 'TNNG',
  VSE = 'VSE',
  WCON = 'WCON',
  WEVG = 'WEVG',
  WN = 'WN',
  WENS = 'WENS',
}

export interface CompanyConfig extends CompanyConfigBaseData {
  companyTag: CompanyTag;
  name: string;
  links: CompanyConfigLinks;
  theme: Theme;
  email: CompanyConfigEmail;
  print?: CompanyConfigPrint;
  registration: CompanyConfigRegistration;
  moreToolsLinks?: {
    crewCalendar?: CompanyConfigMoreToolsLinks;
    materialCatalogExternal?: string;
    materialInvoicing: CompanyConfigMoreToolsLinks;
  };
  purchaseOrganisation?: string;
  appId: string;
}

interface CompanyConfigMoreToolsLinksByRole {
  prod: CompanyConfigMoreToolsUrlByRole;
  default: CompanyConfigMoreToolsUrlByRole;
}

interface CompanyConfigMoreToolsUrlByRole {
  contractor: string;
  default: string;
}

interface CompanyConfigMoreToolsLinksWithoutRole {
  prod: string;
  default: string;
}

type CompanyConfigMoreToolsLinks =
  | CompanyConfigMoreToolsLinksByRole
  | CompanyConfigMoreToolsLinksWithoutRole;

interface CompanyConfigLinks {
  imprint: string;
  privacyPolicy: string;
  termsAndConditions?: string;
  sharepointLink?: string;
}

interface CompanyConfigEmail {
  color: string;
  contactEmail: string;
  name: string;
  logo: CompanyConfigLogo;
  imprintAddress: string;
  imprint: string;
}

interface CompanyConfigLogo {
  src: string;
  alt: string;
  height: string;
  width: string;
}

interface CompanyConfigPrint {
  logoHeight: string;
  address: string;
  imprint: string;
  hideAddressAndImprint?: boolean;
}

interface CompanyConfigRegistration {
  contactClosingSignatureMessageId: string;
}

export interface Theme {
  colors: CompanyConfigThemeColors;
  icons: {
    headerLogo: string;
    headerLogoWidth: number;
    headerLogoHeight: number;
    headerLogoPadding?: PaddingValue;
    footerLogo: string;
    footerLogoWidth: number;
    footerLogoHeight: number;
    favicon: string;
  };
}

export interface SharedConfigThemeColors {
  // Text colors
  colorTextPrimary: string;
  colorTextPrimaryDisabled: string;
  colorTextSecondary: string;
  colorTextTertiary: string;
  colorTextInverted: string;
  // Background colors
  colorBackgroundInteractive: string;
  colorBackgroundInteractiveHover: string;
  colorBackgroundInteractiveActive: string;
  colorBackgroundInteractiveDisabled: string;
  colorBackgroundDecorativeGrey: string;
  colorBackgroundDecorativeGreyLoaderOverlay: string;
  colorBackgroundDecorativeHover: string;
  colorBackgroundDecorativeInverted: string;
  colorBackgroundDecorativeWhite: string;
  // Icon colors
  colorIconInteractive: string;
  colorIconInteractiveDisabled: string;
  colorIconInteractiveHover: string;
  colorIconDecorative: string;
  colorIconInverted: string;
  colorIconException: string;
  colorIconWarning: string;
  // Graphic element colors
  colorGraphicDecorative: string;
  colorGraphicInteractive: string;
  colorGraphicInteractiveHover: string;
  colorGraphicInteractiveDisabled: string;
  // Shadow colors
  colorShadowDropdown: string;

  // Exceptions
  colorTrueBlack: string;
}

export type CompanyConfigThemeColors = SharedConfigThemeColors & {
  /* Color definitions for different companies can be found here:
   * https://confluence.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors

  /* Primary 0
   * Usage: Button active
   */
  colorPrimary0: string;
  /* Primary 1
   * Usage: Button hover
   */
  colorPrimary1: string;
  /* Primary 2
   * Usage: Main color for interactive elements
   */
  colorPrimary2: string;
  /* Primary 3
   * Usage: Focus color and color for horizontal bar above order list
   */
  colorPrimary3: string;
  /* Primary 4
   * Usage: Button/selector disabled
   */
  colorPrimary4: string;
  /* Primary 5
   * Usage: Light background
   */
  colorPrimary5: string;
  /*
   * Primary 50 overlay
   * used for background color DragAndDropArea
   */
  colorPrimary5Overlay: string;
  /* Primary 6
   * Usage: Light background hover
   */
  colorPrimary6: string;

  // Accents colors

  /* Error
   * Usage: Rejected state icon, red exclamation mark, primary notification color
   */
  colorAccentsError: string;
  /* Warning
   */
  colorAccentsWarning: string;
  /* Warning
   */
  colorAccentsSuccess: string;
  /* Highlight
   * Usage: Background of profile bubble in the upper right of the header
   */
  colorAccentsHighlight: string;

  // Text colors

  /* Text Primary Highlight
   */
  colorTextPrimaryHighlight: string;
  /* Text Error
   */
  colorTextError: string;

  // Error colors
  colorError: string;
  colorErrorHover: string;
  colorErrorActive: string;
  colorErrorDisabled: string;
  colorErrorBackground: string;

  // Warning colors
  colorWarning: string;
  colorWarningBackground: string;

  // Success colors
  colorSuccess: string;
  colorSuccessBackground: string;

  // Avatar Highlight colors

  /* Background Avatar
   * Usage: Background color for the avatar icon in the top right corner
   */
  colorBackgroundAvatarHighlight: string;

  /* Text Avatar Highlight
   * Usage: Text color for the avatar icon in the top right corner
   */
  colorTextAvatarHighlight: string;
};

export interface CompanyConfigBaseData {
  companyTag: CompanyTag;
  domain: string;
  name: string;
  contactEmail: string;
  address: Address;
  districtCourt: string;
  commercialRegisterNumber: string;
  taxNumber: string;
  board: Array<string>;
  chairPerson: string;
  personResponsible: string;
}
