export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending',
}

export const SORT_ASCENDING = SortOrder.Ascending;
export const SORT_DESCENDING = SortOrder.Descending;
export const INITIAL_SORT_ORDER = SortOrder.Ascending;

export const toggleJustSortOrder = (x: SortOrder) =>
  x === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING;

export interface Named {
  firstName?: string;
  lastName?: string;
}
