import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  /* Color definitions can be found here:
   * https://confluence.eon.com/display/SMEXNET/Farbmatrix
   */

  // Primary colors
  colorPrimary0: '#480639',
  colorPrimary1: '#60084C',
  colorPrimary2: '#780A5F',
  colorPrimary3: '#BE1097',
  colorPrimary4: '#BB84AF',
  colorPrimary5: '#F1E6EF',
  colorPrimary5Overlay: hexToRgba('#F1E6EF', 0.8),
  colorPrimary6: '#E4CEDF',

  // Accents colors
  colorAccentsError: '#EB4B0A',
  colorAccentsWarning: '#F59B00',
  colorAccentsSuccess: '#64B42D',
  colorAccentsHighlight: '#FFC300',

  // Text colors
  colorTextPrimaryHighlight: '#780A5F', // colorPrimary2
  colorTextError: '#EB4B0A', // colorAccentsError

  // Error colors
  colorError: '#EB4B0A',
  colorErrorHover: '#BC3C08',
  colorErrorActive: '#8D2D06',
  colorErrorDisabled: '#F5A584',
  colorErrorBackground: '#FDEDE6',

  // Warning colors
  colorWarning: '#F59B00',
  colorWarningBackground: '#FCEBCE',

  // Success colors
  colorSuccess: '#64B42D',
  colorSuccessBackground: '#E1F5D3',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#FFC300', // colorAccentsHighlight
  colorTextAvatarHighlight: '#FFFFFF', // colorTextInverted
};

export default colors;
