import { CompanyTag } from 'src/application/theming/companyConfigs/types';

export const companyDomainToCompanyTagMap: Record<string, CompanyTag> = {
  'avacon.de': CompanyTag.AVA,
  'bayernwerk-netz.de': CompanyTag.BAGE,
  'bayernwerk.de': CompanyTag.BAGN,
  'e-dis-netz.de': CompanyTag.EDIS,
  'elbenergie.com': CompanyTag.EENG,
  'evng.de': CompanyTag.EVNG,
  'energis-netzgesellschaft.de': CompanyTag.ENG,
  'hansegas.com': CompanyTag.HANG,
  'hansewerk.com': CompanyTag.HAW,
  'lew.de': CompanyTag.LEW,
  'lew-verteilnetz.de': CompanyTag.LVN,
  'regionetz.de': CompanyTag.REGN,
  'rww.de': CompanyTag.RWW,
  'sh-netz.com': CompanyTag.SHNG,
  'travenetz.de': CompanyTag.TNNG,
  'vse-verteilnetz.de': CompanyTag.VSE,
  'westconnect.de': CompanyTag.WCON,
  'wevg.com': CompanyTag.WEVG,
  'westnetz.de': CompanyTag.WN,
  'westenergie-netzservice.de': CompanyTag.WENS,
};
