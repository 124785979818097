import { Ajax } from '@newrelic/browser-agent/features/ajax';

import { PageAction } from '@newrelic/browser-agent/features/page_action';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { Spa } from '@newrelic/browser-agent/features/spa';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { companyDomainToCompanyTagMap } from 'src/application/company/mapper/companyDomainToCompanyTagMap';
import { AppEnvironment } from 'src/config/AppEnvironment';
import { getConfig } from 'src/config/config';
import { getCurrentEnvironment } from 'src/config/environment';
import { isNotUndefined } from 'src/utils/isUndefined';
import { getBaseDomain } from 'src/utils/urlHelpers';

export const initNewRelicLogging = () => {
  const config = getConfig();

  if (isNotUndefined(config.newRelicConfiguration) || config.newRelicConfiguration != null) {
    const NEW_RELIC_LICENSE_KEY = getNewRelicConfigForEnvironment();
    const NEW_RELIC_URL = 'bam.eu01.nr-data.net';
    const CIAM_AUTHENTICATION_URL = getCIAMLoginURL();
    // New Relic APM code
    const options = {
      init: {
        distributed_tracing: {
          enabled: true,
          exclude_newrelic_header: false,
          cors_use_newrelic_header: true,
          cors_use_tracecontext_headers: true,
          allowed_origins: [
            config.smartExtranetApi.baseUrl,
            config.smartExtranetPublicApi.baseUrl,
            'https://login.microsoftonline.com',
            CIAM_AUTHENTICATION_URL,
          ],
        },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: [NEW_RELIC_URL] },
      },
      info: {
        beacon: NEW_RELIC_URL,
        errorBeacon: NEW_RELIC_URL,
        licenseKey: NEW_RELIC_LICENSE_KEY,
        applicationID: config.newRelicConfiguration.applicationId,
        sa: 1,
      },
      loader_config: {
        accountID: config.newRelicConfiguration.accountId,
        trustKey: config.newRelicConfiguration.trustKey,
        agentID: config.newRelicConfiguration.agentId,
        licenseKey: NEW_RELIC_LICENSE_KEY,
        applicationID: config.newRelicConfiguration.applicationId,
      },
      features: [PageAction, PageViewEvent, PageViewTiming, Ajax, Spa],
    };

    const browserAgent = new BrowserAgent(options);
    const companyTag = companyDomainToCompanyTagMap[getBaseDomain()];
    browserAgent.setCustomAttribute('smexCompanyTag', companyTag);
    browserAgent.setCustomAttribute('smexFrontendEnvironment', getCurrentEnvironment());
    return browserAgent;
  }
};

function getNewRelicConfigForEnvironment(
  environment = getCurrentEnvironment(),
): string | undefined {
  switch (environment) {
    case AppEnvironment.Dev:
      return import.meta.env.VITE_NEW_RELIC_LICENSE_KEY_DEV;
    case AppEnvironment.Qa:
      return import.meta.env.VITE_NEW_RELIC_LICENSE_KEY_QA;
    case AppEnvironment.Prod:
      return import.meta.env.VITE_NEW_RELIC_LICENSE_KEY_PROD;
    default:
      console.info('New Relic is not supported on this environment:', environment);
      return undefined;
  }
}

function getCIAMLoginURL(environment = getCurrentEnvironment()): string | undefined {
  switch (environment) {
    case AppEnvironment.Dev:
    case AppEnvironment.Qa:
      return 'https://account-' + getCurrentEnvironment() + '.' + getBaseDomain();
    case AppEnvironment.Prod:
      return 'https://account.' + getBaseDomain();
    default:
      console.info('CIAM-Login is not supported on this environment:', environment);
      return undefined;
  }
}
