import { mailMessages } from '../../common/commonMessages';
import footerLogo from '../assets/logo-footer-wn.png';
import headerLogo from '../assets/logo-wn.svg';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import colors from './WN.companyConfigColors';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.WN,
  domain: 'westnetz.de',
  name: 'Westnetz GmbH',
  contactEmail: 'smexnet@westnetz.de',
  address: {
    street: 'Florianstraße',
    houseNumber: '15-21',
    zipCode: '44139',
    city: 'Dortmund',
  },
  districtCourt: 'Dortmund',
  commercialRegisterNumber: 'HRB 30872',
  taxNumber: 'DE325265170',
  board: ['Jochen Dwertmann', 'Dr. Jürgen Grönner', 'Dr. Alexander Montebaur'],
  chairPerson: '',
  personResponsible: '',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.westnetz.de/de/impressum.html',
    privacyPolicy:
      'https://www.westnetz.de/content/dam/revu-global/westnetz/documents/datenschutz/datenschutzinformation-zur-Verarbeitung-in-Online-Portalen.pdf',
    termsAndConditions:
      'https://www.westnetz.de/de/fuer-partnerfirmen/fuer-lieferanten-und-messstellenbetreiber/netzeinkauf.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 190,
      headerLogoHeight: 33,
      footerLogo,
      footerLogoWidth: 190,
      footerLogoHeight: 33,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '33',
      width: '190',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.board.join(', ')}<br /><br />${
      baseData.name
    }<br />Sitz ${baseData.address.city} | ${baseData.districtCourt} | ${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '30px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.board.join('<br />')}<br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  purchaseOrganisation: '400E',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
