import { mailMessages } from '../../common/commonMessages';
import logo from '../assets/logo-regn.svg';
import colors from './REGN.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.REGN,
  domain: 'regionetz.de',
  name: 'Regionetz GmbH',
  contactEmail: 'smexnet@regionetz.de',
  address: {
    street: 'Lombardenstraße',
    houseNumber: '12-22',
    zipCode: '52070',
    city: 'Aachen',
  },
  districtCourt: 'Aachen',
  commercialRegisterNumber: 'HRB 12668',
  taxNumber: 'DE 814 121 361',
  board: [],
  chairPerson: 'Axel Kahl, Stefan Ohmen',
  personResponsible: '',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `http://www.${baseData.domain}/kontakt/impressum/`,
    privacyPolicy: `http://www.${baseData.domain}/kontakt/datenschutz/`,
    termsAndConditions: `http://www.${baseData.domain}/fileadmin/regionetz/content/Dokumente/Downloads/AGB_Regionetz_final_2020-12-03.pdf`,
  },
  theme: {
    colors,
    icons: {
      headerLogo: logo,
      headerLogoWidth: 140,
      headerLogoHeight: 32,
      footerLogo: '',
      footerLogoWidth: 0,
      footerLogoHeight: 0,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '75',
      width: '283',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.chairPerson}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Registergericht ${baseData.districtCourt} | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    hideAddressAndImprint: true,
    logoHeight: '46px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.chairPerson}<br /><br />Sitz ${baseData.address.city}<br />Amtsgericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
