import { mailMessages } from '../../common/commonMessages';
import logo from '../assets/logo-wcon.svg';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import colors from './WN.companyConfigColors';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.WCON,
  domain: 'westconnect.de',
  name: 'Westconnect GmbH',
  contactEmail: '',
  address: {
    street: 'Opernplatz',
    houseNumber: '1',
    zipCode: '45128',
    city: 'Essen',
  },
  districtCourt: 'Essen',
  commercialRegisterNumber: 'HRB 22565',
  taxNumber: 'DE 274 009 507',
  board: ['Matthias Krause', 'Carsten Lagemann', 'Robin Weiand'],
  chairPerson: '',
  personResponsible: '',
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `https://www.${baseData.domain}/impressum`,
    privacyPolicy: `https://www.${baseData.domain}/datenschutz`,
    termsAndConditions: `https://www.${baseData.domain}/disclaimer`,
  },
  theme: {
    colors,
    icons: {
      headerLogo: logo,
      headerLogoWidth: 284,
      headerLogoHeight: 37,
      headerLogoPadding: 'leftOnly',
      footerLogo: '',
      footerLogoWidth: 0,
      footerLogoHeight: 0,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '75',
      width: '283',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführung: ${baseData.board.join(
      ', ',
    )}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${
      baseData.name
    }<br />Sitz ${baseData.address.city} | Registergericht ${baseData.districtCourt} | ${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '46px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Geschäftsführung:<br />${baseData.board.join(', ')}<br /><br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
