import { hexToRgba } from '../../../utils/colorHelpers';
import { sharedColors } from './sharedColors';
import { type CompanyConfigThemeColors } from './types';

const colors: CompanyConfigThemeColors = {
  ...sharedColors,

  // Primary colors
  colorPrimary0: '#697907',
  colorPrimary1: '#8CA209',
  colorPrimary2: '#AFCA0B',
  colorPrimary3: '#C2D450',
  colorPrimary4: '#D3DF84',
  colorPrimary5: '#E9EFC1',
  colorPrimary5Overlay: hexToRgba('#E9EFC1', 0.8),
  colorPrimary6: '#F4F7E0',

  // Accents colors
  colorAccentsError: '#D83E38',
  colorAccentsWarning: '#F59B00',
  colorAccentsSuccess: '#64B42D',
  colorAccentsHighlight: '#005136', // colorPrimary0

  // Text colors
  colorTextPrimaryHighlight: '#0050A6', // colorPrimary2
  colorTextError: '#D83E38', // colorAccentsError

  // Error colors
  colorError: '#D83E38',
  colorErrorHover: '#BC3C08',
  colorErrorActive: '#8D2D06',
  colorErrorDisabled: '#F5A584',
  colorErrorBackground: '#FDEDE6',

  // Warning colors
  colorWarning: '#F59B00',
  colorWarningBackground: '#FDF8E5',

  // Success colors
  colorSuccess: '#64B42D',
  colorSuccessBackground: '#E1F5D3',

  // Avatar Highlight colors
  colorBackgroundAvatarHighlight: '#ED7100',
  colorTextAvatarHighlight: '#FFFFFF',
};

export default colors;
