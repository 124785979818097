/*
  Adds an element to the array if it is not already there or removes it otherwise.
 */
const toggle = <T>(arr: Array<T>, toggle: T): Array<T> => {
  const included = arr.includes(toggle);
  if (included) return arr.filter((i) => i !== toggle);
  return [...arr, toggle];
};

/**
 * Creates a grouped array by getKey function.
 * Example:
 * ArrayHelper.groupBy([
 *     {
 *       course: 'Math',
 *       name: 'Joe Mustermann',
 *     },
 *     {
 *       course: 'English',
 *       name: 'Max Test',
 *     },
 *     {
 *       course: 'English',
 *       name: 'Pujan',
 *     },
 *   ],
 *   (student) => student.course
 * )
 * => [
 *       ['Math', [{ course: 'Math', name: 'Joe Mustermann' }]],
 *       ['English', [{ course: 'English', name: 'Max Test' }, { course: 'English', name: 'Pujan' }]]
 *    ]
 */
const groupBy = <T, K>(arr: Array<T>, getKey: (el: T) => K): Map<K, T[]> => {
  const map = new Map<K, T[]>();
  for (const e of arr) {
    const key = getKey(e);
    const existingEntry = map.get(key);
    map.set(key, existingEntry ? [...existingEntry, e] : [e]);
  }
  return map;
};

const remove = <T>(arr: Array<T>, removeElement: T): Array<T> =>
  arr.filter((e) => e !== removeElement);

const findMap = <T, R>(
  arr: ReadonlyArray<T>,
  findAndMap: (e: T) => R | undefined,
): R | undefined => {
  const findMatch = arr.find((e) => findAndMap(e) !== undefined);
  return findMatch === undefined ? undefined : findAndMap(findMatch);
};

export const uniqBy = <T>(arr: Array<T>, iteratee: ((item: T) => unknown | keyof T) | string) => {
  const uniqueKeys = new Set<unknown>();
  const result: Array<T> = [];

  for (const item of arr) {
    // @ts-expect-error no-implicit-any (FIXME)
    const key = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key);
      result.push(item);
    }
  }
  return result;
};

const uniqueTuples = (tuples: [string, string][]): [string, string][] => {
  const grouped = uniqBy(tuples, ([a, b]) => `[${a}][${b}]`).values();
  return [...grouped];
};

const unique = <T extends boolean | string | number>(values: T[]): T[] => [
  ...new Set(values).values(),
];

export const ArrayHelper = {
  toggle,
  groupBy,
  remove,
  findMap,
  uniqueTuples,
  unique,
};

export const getProperty = <T>(object: T, propPath: string) => {
  const props = propPath.split('.');
  let value = object;

  for (const prop of props) {
    // @ts-expect-error no-implicit-any (FIXME)
    value = value[prop];
    if (value === undefined) return;
  }

  return value;
};

export const sortBy = <T>(array: Array<T>, propPath: string) =>
  array.toSorted((a, b) => {
    // Using NaN is a small trick here to consistently deal
    // with undefined values.
    const propA = getProperty(a, propPath) ?? Number.NaN;
    const propB = getProperty(b, propPath) ?? Number.NaN;

    // if propA and propB return 0 to not change the order
    // if propA smaller than propB return -1
    // and if propA larger than propB return 1
    if (propA === propB) {
      return 0; // Values are equal
    } else if (propA < propB) {
      return -1; // propA is less than propB
    } else {
      return 1; // propA is greater than propB
    }
  });
