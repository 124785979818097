import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-wevg.svg';
import { type CompanyConfigBaseData, CompanyTag } from './types';
import colors from './WEVG.companyConfigColors';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.WEVG,
  domain: 'wevg.com',
  name: 'WEVG Salzgitter GmbH & Co. KG',
  contactEmail: 'smexnet@avacon.de',
  address: {
    street: 'Albert-Schweitzer-Str.',
    houseNumber: '7 - 11',
    zipCode: '38226',
    city: 'Salzgitter',
  },
  districtCourt: 'Braunschweig',
  commercialRegisterNumber: 'HRA 200936',
  taxNumber: 'DE 116 880307',
  board: [],
  chairPerson: 'Oberbürgermeister Frank Klingebiel',
  personResponsible: '',
};

const company = {
  ...baseData,
  links: {
    imprint: `https://www.${baseData.domain}/impressum/`,
    privacyPolicy: `https://www.${baseData.domain}/datenschutz/`,
    termsAndConditions: `https://www.${baseData.domain}/rechtliche-hinweise/`,
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 40,
      headerLogoHeight: 38,
      footerLogo: '',
      footerLogoWidth: 0,
      footerLogoHeight: 0,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '88',
      width: '94',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Vorsitzender des Aufsichtsrates: ${baseData.chairPerson}<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${baseData.name}<br />Sitz ${baseData.address.city} | Amtsgericht ${baseData.districtCourt} | ${baseData.commercialRegisterNumber}<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Vorsitzender des<br />Aufsichtsrates:<br />${baseData.chairPerson}<br />Sitz ${baseData.address.city}<br />Amtsgericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  purchaseOrganisation: '0948',
  appId: companyTagToAppId[baseData.companyTag],
};
export default company;
