import { mailMessages } from '../../common/commonMessages';
import vseLogo from '../assets/logo-vse.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.VSE,
  domain: 'vse-verteilnetz.de',
  name: 'VSE Verteilnetz GmbH',
  contactEmail: '???',
  address: {
    street: 'Heinrich-Böcking-Str.',
    houseNumber: '10 - 14',
    zipCode: '66121',
    city: 'Saarbrücken',
  },
  districtCourt: 'Saarbrücken',
  commercialRegisterNumber: 'HRB 16457',
  taxNumber: 'DE253327588',
  board: ['Roman Fixemer', 'Peter Stein'],
  chairPerson: '',
  personResponsible: '',
};

const company: CompanyConfig = {
  ...baseData,
  links: {
    imprint: `https://www.${baseData.domain}/impressum.html`,
    privacyPolicy: `https://www.${baseData.domain}/datenschutz.html`,
    termsAndConditions: `https://www.${baseData.domain}/unser-stromnetz/infopflichten.html`,
  },
  theme: {
    colors,
    icons: {
      headerLogo: vseLogo,
      headerLogoWidth: 800,
      headerLogoHeight: 400,
      footerLogo: vseLogo,
      footerLogoWidth: 800,
      footerLogoHeight: 400,
      favicon: `/favicons/favicon-${baseData.companyTag.toLowerCase()}.ico`,
    },
  },
  email: {
    name: baseData.name,
    logo: {
      src: `https://smexnet.${
        baseData.domain
      }/assets/logo-${baseData.companyTag.toLowerCase()}.png`,
      alt: `${baseData.name} Logo`,
      height: '33',
      width: '184',
    },
    imprintAddress: `${baseData.name} | ${baseData.address.street} ${baseData.address.houseNumber} | ${baseData.address.zipCode} ${baseData.address.city}`,
    imprint: `Geschäftsführer: ${baseData.board.join(', ')}<br />Vorsitzender des Aufsichtsrates: ${
      baseData.chairPerson
    }<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: ${baseData.personResponsible}<br /><br />${
      baseData.name
    }<br />Sitz ${baseData.address.city} | Amtsgericht ${baseData.districtCourt} | ${
      baseData.commercialRegisterNumber
    }<br />USt-ID-Nr. ${baseData.taxNumber}<br />`,
    contactEmail: baseData.contactEmail,
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: `${baseData.address.street} ${baseData.address.houseNumber}<br />${baseData.address.zipCode} ${baseData.address.city}<br />www.${baseData.domain}`,
    imprint: `Vorsitzender des<br />Aufsichtsrates:<br />${
      baseData.chairPerson
    }<br /><br />Geschäftsführer:<br />${baseData.board.join('<br />')}<br />Sitz ${
      baseData.address.city
    }<br />Amtsgericht ${baseData.districtCourt}<br />${baseData.commercialRegisterNumber}`,
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  // TODO: clarify and insert correct data
  appId: companyTagToAppId[baseData.companyTag],
};
export default company;
