export enum AppEnvironment {
  Dev = 'dev',
  Qa = 'qa',
  Poc = 'poc',
  Prod = 'prod',
  Local = 'development',
  Test = 'test',
  Default = 'default',
  StorybookDev = 'storybook-dev',
  StorybookQa = 'storybook-qa',
  StorybookProd = 'storybook-prod',
}

export enum StageEnvironment {
  Dev = AppEnvironment.Dev,
  Qa = AppEnvironment.Qa,
}

export const isAppEnvironment = (arg?: string): arg is AppEnvironment =>
  !!arg && Object.values(AppEnvironment).includes(arg as AppEnvironment);
