export interface Address {
  city: string;
  houseNumber?: string | null;
  street?: string | null;
  zipCode?: string | null;
}

export const DEFAULT_ADDRESS: Required<Address> = {
  city: '',
  houseNumber: '',
  street: '',
  zipCode: '',
};
