import { hexToRgba } from '../../../utils/colorHelpers';
import { type SharedConfigThemeColors } from './types';

export const sharedColors: SharedConfigThemeColors = {
  // Text colors
  colorTextPrimary: '#191919',
  colorTextPrimaryDisabled: '#BCBCBC',
  colorTextSecondary: '#404040',
  colorTextTertiary: '#6E6E6E',
  colorTextInverted: '#FFFFFF',

  // Background color
  colorBackgroundInteractive: '#EAEAEA',
  colorBackgroundInteractiveHover: '#E0E0E0',
  colorBackgroundInteractiveActive: '#D7D7D7',
  colorBackgroundInteractiveDisabled: '#F4F4F4',
  colorBackgroundDecorativeGrey: '#FAFAFA',
  colorBackgroundDecorativeGreyLoaderOverlay: hexToRgba('#FAFAFA', 0.85),
  colorBackgroundDecorativeHover: '#F2F2F2',
  colorBackgroundDecorativeInverted: '#191919',
  colorBackgroundDecorativeWhite: '#FFFFFF',

  // Icon colors
  colorIconInteractive: '#6E6E6E',
  colorIconInteractiveDisabled: '#BCBCBC',
  colorIconInteractiveHover: '#404040',
  colorIconDecorative: '#9B9B9B',
  colorIconInverted: '#FFFFFF',
  colorIconException: '#191919',
  colorIconWarning: '#B98E22',

  // Graphic element colors
  colorGraphicDecorative: '#D7D7D7',
  colorGraphicInteractive: '#ADADAD',
  colorGraphicInteractiveHover: '#767676',
  colorGraphicInteractiveDisabled: '#E5E5E5',

  // Shadow colors
  colorShadowDropdown: 'rgba(0, 0, 0, 0.14)',

  // Exceptions
  colorTrueBlack: '#000000',
};
