import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';
import { CompanyTag } from 'src/application/theming/companyConfigs/types';
import { StageEnvironment } from 'src/config/AppEnvironment';
import {
  getAadClientIdForStageEnvironment,
  getCiamClientIdForStageEnvironment,
} from 'src/config/helper/getClientIdForStageEnvironment';
import { getDomainByEnvAndCompanyTag } from 'src/config/helper/getDomainByEnvAndCompanyTag';
import { getRedirectUrlByEnvAndCompanyTag } from 'src/config/helper/getRedirectUrlByEnvAndCompanyTag';
import { AuthProvider, type Configuration } from 'src/config/type';

// CHANGE THESE TWO TO SWITCH ENV AND COMPANY
const ENV: StageEnvironment = StageEnvironment.Dev;
const COMPANY_TAG: CompanyTag = CompanyTag.SHNG;

// Ensure to have set the domain to companyTag entry within application/company/mapper/companyDomainToCompanyTagMap.ts
const DOMAIN = getDomainByEnvAndCompanyTag(ENV, COMPANY_TAG);
const REDIRECT_BASE_URL = getRedirectUrlByEnvAndCompanyTag(ENV, COMPANY_TAG);
// Ensure to have appId entry set in application/company/mapper/companyTagToAppId.ts
const APP_ID = companyTagToAppId[COMPANY_TAG];
const CIAM_CLIENT_ID = getCiamClientIdForStageEnvironment(ENV);
const AAD_CLIENT_ID = getAadClientIdForStageEnvironment(ENV);

const ENV_TO_API_ROUTE: Record<StageEnvironment, string> = {
  [StageEnvironment.Dev]: 'dev',
  [StageEnvironment.Qa]: 'qas',
};

export const localAppConfig: Configuration = {
  authentication: {
    isEnabled: true,
    defaultProvider: AuthProvider.CIAM,
    providers: [
      {
        name: AuthProvider.CIAM,
        authBaseUrl: `${DOMAIN}/services/oauth2/authorize`,
        tokenBaseUrl: `${DOMAIN}/services/oauth2/token`,
        logoutBaseUrl: `${DOMAIN}/secur/logout.jsp?retUrl=/apex/CIAM_SmartExtranetLogout?rls=0`,
        clientId: CIAM_CLIENT_ID,
        mfaRegisterUrl: `${DOMAIN}/s/2fa-registration?appId=${APP_ID}`,
        redirectUrl: `${REDIRECT_BASE_URL}/oauth/return/local`,
        scope: 'openid profile refresh_token',
      },
      {
        name: AuthProvider.AAD,
        authBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/authorize',
        tokenBaseUrl:
          'https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/token',
        logoutBaseUrl: '/',
        clientId: AAD_CLIENT_ID,
        redirectUrl: `${REDIRECT_BASE_URL}/oauth/return/local`,
        scope: 'openid profile email User.Read',
      },
    ],
  },
  maintenanceEnabled: false,
  maintenanceExcludedCompanies: [
    CompanyTag.AVA,
    CompanyTag.BAGE,
    CompanyTag.BAGN,
    CompanyTag.EDIS,
    CompanyTag.EENG,
    CompanyTag.EVNG,
    CompanyTag.ENG,
    CompanyTag.REGN,
    CompanyTag.HANG,
    CompanyTag.HAW,
    CompanyTag.LEW,
    CompanyTag.LVN,
    CompanyTag.RWW,
    CompanyTag.SHNG,
    CompanyTag.TNNG,
    CompanyTag.VSE,
    CompanyTag.WCON,
    CompanyTag.WEVG,
    CompanyTag.WN,
  ],
  smartExtranetApi: {
    apiToken: 'USE_OPEN_ID_CONNECT_ACCESS_TOKEN',
    baseUrl: `https://smex-api-${ENV_TO_API_ROUTE[ENV]}.eon.com/api/${COMPANY_TAG}`,
    mockedEndpoints: [],
  },
  smartExtranetPublicApi: {
    baseUrl: `https://smex-api-${ENV_TO_API_ROUTE[ENV]}.eon.com/public/${COMPANY_TAG}`,
  },
  companyTag: COMPANY_TAG,
  googleMapsJavascriptApi: {
    authentication: {
      client: 'gme-eonag',
      v: '3.42',
    },
  },
  azureApplicationInsights: {
    instrumentationKey: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
  },
  recaptchaSiteKey: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
  serviceDeskUrl:
    'https://sigmatest.service-now.com/smart_extranet?id=support_request&sys_id=9585668c1b55d1104af732649b4bcba8',
};
