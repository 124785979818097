import { defineMessages, IntlProvider, useIntl } from 'react-intl';
import styled from 'styled-components';
import LoaderOverlay from 'src/components/LoaderOverlay';
import { LocaleSource, useI18NMessages, useI18NSettings } from 'src/utils/i18n/useI18nSettings';

const MaintenancePage = (): JSX.Element => {
  const i18NSettings = useI18NSettings(LocaleSource.UseDefaultLocale);
  const i18NMessages = useI18NMessages(i18NSettings.userLocale);

  if (i18NMessages.isPending) {
    return <LoaderOverlay visible />;
  }

  return (
    <IntlProvider
      defaultLocale={i18NSettings.defaultLocale}
      locale={i18NSettings.userLocale}
      messages={i18NMessages.data}
    >
      <MaintenanceBlock />
    </IntlProvider>
  );
};
const MaintenanceBlock = (): JSX.Element => {
  const { formatMessage: t } = useIntl();
  return (
    <S.MaintenanceBlock>
      <h1>{t(messages.maintenanceTitle)}</h1>
      <p>{t(messages.maintenanceParagraph)}</p>
    </S.MaintenanceBlock>
  );
};

namespace S {
  export const MaintenanceBlock = styled.div`
    padding: 20px 40px;
    font-family: Arial, sans-serif;
    color: #000;
  `;
}

const messages = defineMessages({
  maintenanceTitle: {
    id: 'app.maintenance.title',
    defaultMessage: 'Wartungsarbeiten',
  },
  maintenanceParagraph: {
    id: 'app.maintenance.paragraph',
    defaultMessage:
      'Wir führen im Moment Wartungsarbeiten aus. Bitte versuchen Sie es später erneut.',
  },
});

export default MaintenancePage;
