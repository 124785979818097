import queryString from 'query-string';
import { companyDomainToCompanyTagMap } from 'src/application/company/mapper/companyDomainToCompanyTagMap';
import { CompanyTag } from 'src/application/theming/companyConfigs/types';
import { AppEnvironment } from 'src/config/AppEnvironment';
import { getConfig } from 'src/config/config';
import { getCurrentEnvironment, isLocalEnvironment } from 'src/config/environment';
import { getBaseDomain } from 'src/utils/urlHelpers';

export const getCurrentCompanyTag = (): CompanyTag | undefined => {
  const environment = getCurrentEnvironment();
  const config = getConfig(environment);
  const companyTagFromDomain = companyDomainToCompanyTagMap[getBaseDomain()];

  if (companyTagFromDomain) {
    return companyTagFromDomain;
  }

  const isLocalEnv = isLocalEnvironment(environment);

  // When running in local environment, override the `companyTag` in the config
  // file with the URL query parameter `companyTag`
  if (isLocalEnv) {
    const query = queryString.parse(window.location.search);
    const queryCompanyTag: string =
      (Array.isArray(query.companyTag) ? query.companyTag[0] : query.companyTag) ?? '';

    // @ts-expect-error no-implicit-any (FIXME)
    if (CompanyTag[queryCompanyTag]) {
      return queryCompanyTag as CompanyTag;
    }
  }

  if (config.companyTag && (isLocalEnv || environment === AppEnvironment.Test)) {
    return config.companyTag;
  }

  console.error('Could not determine company tag');

  return undefined;
};
