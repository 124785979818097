// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-eeng.svg';
import colors from './default.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.EENG,
  domain: 'elbenergie.com',
  name: 'ElbEnergie GmbH',
  // TODO: clarify and insert correct data --- START
  contactEmail: 'smexnet@sh-netz.com',
  address: {
    street: 'Lilienthalstraße',
    houseNumber: '7',
    zipCode: '93049',
    city: 'Regensburg',
  },
  districtCourt: 'Regensburg',
  commercialRegisterNumber: 'HRB 9476',
  taxNumber: 'DE 814 365 771',
  board: [],
  chairPerson: 'Gudrun Alt, Dr. Joachim Kabs, Robert Pflügl',
  personResponsible: 'Maximilian Zängl',
  // TODO: clarify and insert correct data --- END
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.elbenergie.com/de/elbenergie/impressum.html',
    privacyPolicy: 'https://www.elbenergie.com/de/elbenergie/datenschutz.html',
    termsAndConditions: 'https://www.elbenergie.com/de/elbenergie/agb.html',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 98,
      headerLogoHeight: 36,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 98,
      footerLogoHeight: 36,
      favicon: '/favicons/favicon-shng.ico',
    },
  },
  email: {
    name: 'ElbEnergie GmbH',
    logo: {
      src: 'https://smexnet.sh-netz.com/assets/logo-eeng.png',
      alt: 'ElbEnergie GmbH Logo',
      height: '36',
      width: '98',
    },
    imprintAddress: 'ElbEnergie GmbH | An der Reitbahn 17 | 21218 Seevetal',
    imprint:
      'Geschäftsführung: Christine Rudnik<br />Verantwortlicher gemäß § 55 Abs. 2 RStV: Ove Struck<br /><br />ElbEnergie GmbH<br />Sitz: Seevetal/Hittfeld | Amtsgericht Lüneburg | HRB 208251<br/>USt-ID-Nr. DE319088072<br />',
    contactEmail: 'smexnet@sh-netz.com',
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '54px',
    address: 'An der Reitbahn 17<br />21218 Seevetal<br />www.elbenergie.com',
    imprint:
      'Geschäftsführung:<br />Christine Rudnik<br /><br /><br />Sitz: Seevetal/Hittfeld<br />Amtsgericht Lüneburg<br />HRB 208251',
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  moreToolsLinks: {
    materialInvoicing: {
      prod: 'https://exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=pwg.apps.eon.com&TARGET=https%3A%2F%2Fexsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000036',
      default:
        'https://q-exsp.eon-apps.com/GetAccess/Saml/Portal/Transfer?GA_SAML_SP=qwg.apps.eon.com&TARGET=https%3A%2F%2Fq-exsp.eon-apps.com%2Fwebdynpro%2Fdispatcher%2Feonis.com%2Feea%7Ebpexd%7Ewd%7Emain%2FEXD%3Fnavtarget%3Daufmass%26ignoreactionportal%3Dtrue%26compid%3D1000000000%26spid%3D1000000015',
    },
  },
  purchaseOrganisation: '1571',
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
