import styled, { css } from 'styled-components';

interface FontProps {
  children: React.ReactNode | string[] | string;
  ellipsis?: boolean;
  weight?: 'thin' | 'bold' | 'normal';
}

const fontFamilyThin = "'Polo-Leicht', sans-serif";
const fontFamilyDefault = "'Polo-Buch', sans-serif";
const fontFamilyBold = "'Polo-Krftg', sans-serif";

const FontBase = css<FontProps>`
  font-family: ${fontFamilyDefault};
  ${({ weight }) => weight === 'thin' && `font-family: ${fontFamilyThin};`}
  ${({ weight }) => weight === 'bold' && `font-family: ${fontFamilyBold};`}
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    `}
  font-weight: normal;
  margin: 0;

  strong {
    font-family: ${fontFamilyBold};
  }
`;

export const Font27 = styled.p`
  ${FontBase}
  font-size: 2.7rem;
`;
export const Font21 = styled.p`
  ${FontBase}
  font-size: 2.1rem;
`;
export const Font18 = styled.p`
  ${FontBase}
  font-size: 1.8rem;
`;
export const Font16 = styled.p`
  ${FontBase}
  font-size: 1.6rem;
`;
export const Font14 = styled.p`
  ${FontBase}
  font-size: 1.4rem;
`;
export const Font12 = styled.p`
  ${FontBase}
  font-size: 1.2rem;
`;
