// TODO: Add logo import if it's available
// import footerLogo from '../assets/logo-footer.svg';
import { mailMessages } from '../../common/commonMessages';
import headerLogo from '../assets/logo-lew.svg';
import colors from './LEW_LVN.companyConfigColors';
import { type CompanyConfig, type CompanyConfigBaseData, CompanyTag } from './types';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';

const baseData: CompanyConfigBaseData = {
  companyTag: CompanyTag.LEW,
  domain: 'lew.de',
  name: 'Lechwerke AG',
  contactEmail: 'smexnet@lew.de',
  // TODO: clarify and insert correct data --- START
  address: {
    street: 'Lilienthalstraße',
    houseNumber: '7',
    zipCode: '93049',
    city: 'Regensburg',
  },
  districtCourt: 'Regensburg',
  commercialRegisterNumber: 'HRB 9476',
  taxNumber: 'DE 814 365 771',
  board: [],
  chairPerson: 'Gudrun Alt, Dr. Joachim Kabs, Robert Pflügl',
  personResponsible: 'Maximilian Zängl',
  // TODO: clarify and insert correct data --- END
};

const companyConfig: CompanyConfig = {
  ...baseData,
  links: {
    imprint: 'https://www.lew.de/impressum',
    privacyPolicy: 'https://www.lew.de/datenschutz',
    termsAndConditions: 'https://www.lew.de/disclaimer',
  },
  theme: {
    colors,
    icons: {
      headerLogo,
      headerLogoWidth: 48,
      headerLogoHeight: 32,
      // TODO: Replace '' if logo is available
      footerLogo: '',
      footerLogoWidth: 60,
      footerLogoHeight: 40,
      favicon: '/favicons/favicon-lew.ico',
    },
  },
  email: {
    name: 'Lechwerke AG',
    logo: {
      src: 'https://smexnet.lew.de/assets/logo-lew.png',
      alt: 'Lechwerke AG',
      height: '40',
      width: '60',
    },
    imprintAddress: 'Lechwerke AG | Schaezlerstraße 3 | 86150 Augsburg',
    imprint:
      'Vorstand: Dr. Dietrich Gemmel, Christian Barr<br />Vorsitzender des Aufsichtsrates: Bernd Böddeling<br /><br />Lechwerke AG<br />Sitz Augsburg | Amtsgericht Augsburg | HRB 6164<br />USt-ID-Nr. DE 1274 70 129<br />',
    contactEmail: 'smexnet@lew.de',
    color: colors.colorPrimary2,
  },
  print: {
    logoHeight: '60px',
    address: 'Schaezlerstraße 3<br />86150 Augsburg<br />www.lew.de',
    imprint:
      'Vorstand: Dr. Dietrich Gemmel, Christian Barr<br />Vorsitzender des Aufsichtsrates: Bernd Böddeling<br /><br />Lechwerke AG<br />Sitz Augsburg | Amtsgericht Augsburg | HRB 6164<br />USt-ID-Nr. DE 1274 70 129',
  },
  registration: {
    contactClosingSignatureMessageId: mailMessages.emailClosingDefault.id,
  },
  appId: companyTagToAppId[baseData.companyTag],
};

export default companyConfig;
