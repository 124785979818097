import sha256 from 'crypto-js/sha256';
import { getConfig } from '../config/config';
import { getCurrentCompanyTag } from 'src/application/theming/companyConfigs/helper/getCurrentCompanyTag';

const getTimeBoundHash = (): string => {
  const now = new Date();
  return String(sha256(`skip${now.getFullYear()}${now.getMonth() + 1}`));
};

const skipMaintenanceByUrlParam = (): boolean => {
  const timeBoundHash = getTimeBoundHash();
  const skipMaintenance = window.location.search.includes(timeBoundHash);
  if (skipMaintenance) {
    sessionStorage.setItem('skipMaintenance', timeBoundHash);
  }
  return sessionStorage.getItem('skipMaintenance') === timeBoundHash;
};

export const isInMaintenanceMode = (): boolean => {
  try {
    if (skipMaintenanceByUrlParam()) {
      return false;
    }
    const config = getConfig();
    const currentCompanyTag = getCurrentCompanyTag();
    if (!currentCompanyTag || !config) {
      return false;
    }
    return (
      config.maintenanceEnabled === true &&
      !(config.maintenanceExcludedCompanies ?? []).includes(currentCompanyTag)
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};
