/**
 * Color Helpers
 */
function intStringToHex(numberString: string): string {
  const number = Number.parseInt(numberString.trim(), 10);
  return number.toString(16).padStart(2, '0');
}

export function rgbaToHex(rgba: string, includeAlpha = false): string {
  const parts = rgba.slice(Math.max(0, rgba.indexOf('('))).split(',');
  const r = intStringToHex(parts[0].slice(1));
  const g = intStringToHex(parts[1].slice(1));
  const b = intStringToHex(parts[2].slice(1));
  let hexColor = `#${r}${g}${b}`;

  if (includeAlpha) {
    const aFloat = Number.parseFloat(parts[3].slice(0, Math.max(0, parts[3].length - 1)).trim());
    const a = Number.parseInt((aFloat * 255).toString(), 10);
    hexColor += a.toString(16).padStart(2, '0');
  }

  return hexColor;
}

export function hexToRgba(hex: string, alpha?: number) {
  const hexRegexp = /^#?([\dA-Fa-f]{2})([\dA-Fa-f]{2})([\dA-Fa-f]{2})$/;
  if (hexRegexp.test(hex)) {
    const result = hexRegexp.exec(hex);
    if (result) {
      const [, r, g, b] = result;
      const colorValues = [
        Number.parseInt(r, 16).toString(),
        Number.parseInt(g, 16).toString(),
        Number.parseInt(b, 16).toString(),
        alpha?.toFixed(2) ?? '1',
      ].join(', ');
      return `rgba(${colorValues})`;
    }
  } else {
    console.warn(`hexToRgba: Wrong format of parameter hex provided "${hex}"`);
  }
  return 'rgba(0, 0, 0, 1)';
}
