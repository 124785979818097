import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense, lazy } from 'react';
import { isInMaintenanceMode } from './maintenance-page/isInMaintenanceMode';
import MaintenancePage from './maintenance-page/MaintenancePage';
import { queryClient } from 'src/queryClient';

const RootWithContext = lazy(() => import('./RootWithContext'));

export const SmexnetLazyLoader = (): JSX.Element => (
  <Suspense fallback={<></>}>
    {isInMaintenanceMode() ? (
      <QueryClientProvider client={queryClient}>
        <MaintenancePage />
      </QueryClientProvider>
    ) : (
      <RootWithContext />
    )}
  </Suspense>
);
