import { defineMessages } from 'react-intl';
import { SortOrder } from '../tableSorting/resources';
import { AcademicTitle, Gender } from 'src/types';

export const commonMessages = defineMessages({
  reloadOrRetryLater: {
    id: 'app.common.reloadOrRetryLater',
    defaultMessage: 'Bitte laden Sie die Seite neu oder versuchen Sie es später noch einmal.',
  },
  phoneNumber: {
    id: 'app.label.phoneNumber',
    defaultMessage: 'Telefonnummer',
  },
  defaultValueNotSet: {
    id: 'app.default.value.notSet',
    defaultMessage: 'nicht angegeben',
  },
  tooltipCouldNotLoadData: {
    id: 'app.tooltip.couldNotLoadData',
    defaultMessage: 'Benötigte Daten konnten nicht geladen werden.',
  },
  errorWithMessage: {
    id: 'app.error.message',
    defaultMessage: 'Fehler {message} ',
  },
  errorCouldNotLoad: {
    id: 'app.error.couldNotLoad',
    defaultMessage: 'Laden fehlgeschlagen',
  },
  characters: {
    id: 'app.common.characters',
    defaultMessage: 'Zeichen',
  },
  confirm: {
    id: 'app.label.confirm',
    defaultMessage: 'Bestätigen',
  },
  cancel: {
    id: 'app.common.cancel',
    defaultMessage: 'Abbrechen',
  },
  deleteExecutingLabel: {
    id: 'app.common.deleteButton.isExecuting.label',
    defaultMessage: 'Löschen ...',
  },
  deleteLabel: {
    id: 'app.common.deleteButton.label',
    defaultMessage: 'Löschen',
  },
  select: {
    id: 'app.common.select.label',
    defaultMessage: 'Bitte wählen',
  },
  serverLoadingFilterValuesFailed: {
    id: 'app.common.serverErrorMessages.loadingFilterValuesFailed',
    defaultMessage:
      'Einige Filterdaten konnten nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
  },
  yes: {
    id: 'app.common.yes',
    defaultMessage: 'Ja',
  },
  no: {
    id: 'app.common.no',
    defaultMessage: 'Nein',
  },
} as const);

export const sortOrderMessages = defineMessages({
  [SortOrder.Ascending]: {
    id: 'app.label.sortOrder.ascending',
    defaultMessage: 'aufsteigend',
  },
  [SortOrder.Descending]: {
    id: 'app.label.sortOrder.descending',
    defaultMessage: 'absteigend',
  },
});

export const mailMessages = defineMessages({
  emailClosingSHNG: {
    id: 'email.closing.shng.short',
    defaultMessage: 'Ihr Netzbetreiber, die Schleswig-Holstein Netz GmbH',
  },
  emailClosingAVA: {
    id: 'email.closing.ava.short',
    defaultMessage: 'Ihr Netzbetreiber, die Avacon Netz GmbH',
  },
  emailClosingBAGE: {
    id: 'email.closing.bage.short',
    defaultMessage: 'Ihr Netzbetreiber, die Bayernwerk Netz GmbH',
  },
  emailClosingDefault: {
    id: 'email.closing.default.short',
    defaultMessage: 'Ihr Netzbetreiber',
  },
});

export const salutationMessages = defineMessages<Gender>({
  [Gender.None]: {
    id: 'app.common.salutation.notSpecified',
    defaultMessage: 'Keine Angabe',
  },
  [Gender.Female]: {
    id: 'app.common.salutation.female',
    defaultMessage: 'Frau',
  },
  [Gender.Male]: {
    id: 'app.common.salutation.male',
    defaultMessage: 'Herr',
  },
} as const);

export const academicTitleMessages = defineMessages<AcademicTitle>({
  [AcademicTitle.None]: {
    id: 'app.common.academicTitle.none',
    defaultMessage: ' ',
  },
  [AcademicTitle.Dr]: {
    id: 'app.common.academicTitle.dr',
    defaultMessage: 'Dr.',
  },
  [AcademicTitle.Prof]: {
    id: 'app.common.academicTitle.prof',
    defaultMessage: 'Prof.',
  },
} as const);
