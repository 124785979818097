import { AppEnvironment } from './AppEnvironment';
import { devAppConfig } from './config.dev';
import { localAppConfig } from './config.local';
import { pocAppConfig } from './config.poc';
import { prodAppConfig } from './config.prod';
import { qaAppConfig } from './config.qa';
import configTest from './config.test';
import { getCurrentEnvironment } from './environment';
import { companyDomainToCompanyTagMap } from 'src/application/company/mapper/companyDomainToCompanyTagMap';
import { companyTagToAppId } from 'src/application/company/mapper/companyTagToAppId';
import {
  type AuthenticationConfiguration,
  type AuthenticationProviderConfiguration,
  AuthProvider,
  type Configuration,
} from 'src/config/type';
import { assertUnreachable } from 'src/utils/assertUnreachable';
import { getBaseDomain } from 'src/utils/urlHelpers';

const getConfigForEnvironment = (environment = getCurrentEnvironment()): Configuration => {
  switch (environment) {
    case AppEnvironment.Dev:
    case AppEnvironment.StorybookDev:
    case AppEnvironment.Default:
      return devAppConfig;
    case AppEnvironment.Qa:
    case AppEnvironment.StorybookQa:
      return qaAppConfig;
    case AppEnvironment.Poc:
      return pocAppConfig;
    case AppEnvironment.Prod:
    case AppEnvironment.StorybookProd:
      return prodAppConfig;
    case AppEnvironment.Local:
      return localAppConfig;
    case AppEnvironment.Test:
      return configTest;
    default:
      return assertUnreachable(environment);
  }
};

export const getConfig = (environment = getCurrentEnvironment()): Configuration => {
  const config = getConfigForEnvironment(environment) as Configuration;
  return replacePlaceholder(config);
};

const replacePlaceholder = (config: Configuration): Configuration => ({
  ...config,
  authentication: {
    ...config.authentication,
    ...applyAuthConfigReplacements(config.authentication),
  },
});

const getReplacementProviderConfigKeys = (providerConfig: AuthenticationProviderConfiguration) =>
  providerConfig.name === AuthProvider.CIAM
    ? ['authBaseUrl', 'logoutBaseUrl', 'redirectUrl', 'tokenBaseUrl', 'mfaRegisterUrl']
    : ['authBaseUrl', 'logoutBaseUrl', 'redirectUrl', 'tokenBaseUrl'];

const replaceAuthProviderConfigurationVariables = (
  providerConfig: AuthenticationProviderConfiguration,
  domain: string,
  appId: string,
): AuthenticationProviderConfiguration => ({
  ...providerConfig,
  ...Object.fromEntries(
    getReplacementProviderConfigKeys(providerConfig).map((key) => [
      key,
      // @ts-expect-error no-implicit-any
      (providerConfig[key] as string)
        .replaceAll('${DOMAIN}', domain)
        .replaceAll('${APP_ID}', appId),
    ]),
  ),
});

const applyAuthConfigReplacements = (
  authConf: AuthenticationConfiguration,
): AuthenticationConfiguration => {
  const domain = getBaseDomain();
  const companyTag = companyDomainToCompanyTagMap[domain];
  const appId = companyTagToAppId[companyTag];

  return authConf.isEnabled
    ? {
        ...authConf,
        providers: authConf.providers.map((authProviderConfig) =>
          replaceAuthProviderConfigurationVariables(authProviderConfig, domain, appId),
        ),
      }
    : authConf;
};

export const isStyleguideAllowed = (): boolean =>
  ![AppEnvironment.Qa, AppEnvironment.Prod].includes(getCurrentEnvironment());

export const isEmailTemplatesAllowed = (): boolean =>
  ![AppEnvironment.Qa, AppEnvironment.Prod].includes(getCurrentEnvironment());
